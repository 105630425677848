import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from "react-i18next";

const SearchForm = ({ onSearch, loading }) => {
    const { t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');

    const formatLicensePlate = (term) => {
        const patterns = [
            /^([A-Z]{2})(\d{3})([A-Z]{2})$/,
            /^([A-Z]{2})(\d{3}) ([A-Z]{2})$/,
            /^([A-Z]{2}) (\d{3})([A-Z]{2})$/
        ];

        for (let pattern of patterns) {
            if (pattern.test(term)) {
                return term.replace(pattern, "$1 $2 $3");
            }
        }
        return term;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (searchTerm.length === 0) {
            return;
        }
        let upperCaseSearchTerm = searchTerm.toUpperCase();
        const isReference = /^\d{7}$/.test(upperCaseSearchTerm);
        const isVIN = /^.{17}$/.test(upperCaseSearchTerm);

        let searchType;
        if (isReference) {
            searchType = 'Project';
        } else if (isVIN) {
            searchType = 'NumeroSerie';
        } else {
            searchType = 'Immatriculation';
            upperCaseSearchTerm = formatLicensePlate(upperCaseSearchTerm);
        }
        onSearch(searchType, upperCaseSearchTerm);
    };

    return (
        <form
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '40px 20px',
                gap: '15px',
            }}
            onSubmit={handleSubmit}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                }}
            >
                {loading && <CircularProgress size={24} />}
            </div>
            <input
                style={{
                    padding: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    marginLeft: '10px',
                }}
                type="text"
                placeholder={t('searchForm:vinLicensePLateOrCaseRef')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
                className="search-form-button"
                style={{
                    width: '120px',
                    padding: '10px 15px',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
                type="submit"
            >
                {t('searchForm:search')}
            </button>
        </form>
    );
};

export default SearchForm;
