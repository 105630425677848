import axios from "axios";

const UserService = {
    getUserData: async () => {
        return axios.get(`/.auth/me`,
            {
                headers: {'Content-type': 'application/json; charset=utf-8'}
            }
        );
    }
};

export default UserService;
