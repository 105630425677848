import React, { useEffect, useState } from 'react';
import Description from '@mui/icons-material/Description';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from "react-i18next";

const DocumentList = ({ vehicleList }) => {
    const { t} = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (vehicleList && vehicleList.length > 0) {
            setCurrentIndex(vehicleList.length - 1);
        }
    }, [vehicleList]);

    if (!vehicleList || vehicleList.length === 0) {
        return null;
    }

    const vehicle = vehicleList[currentIndex];
    const isFirst = currentIndex === 0;
    const isLast = currentIndex === vehicleList.length - 1;

    const documentItems = [
        { key: 'CG', label: t('documentList:CG') },
        { key: 'ET', label: t('documentList:ET') },
        { key: 'CT', label: t('documentList:CT') },
        { key: 'BE', label: t('documentList:BE') }
    ];

    const handleChangeVehicle = (value) => {
        const newIndex = currentIndex + value;
        if (newIndex >= 0 && newIndex < vehicleList.length) {
            setCurrentIndex(newIndex);
        }
    };

    const renderDocumentItem = (item) => {
        const urlKey = `Url${item.key}`;
        const url = vehicle[urlKey];
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                }}
                key={item.key}
            >
                {url ? <Description style={{ color: 'green' }} /> : <HighlightOffIcon style={{ color: 'grey' }} />}
                {url ?
                    <a
                        className="document-item"
                        style={{
                            marginLeft: '10px',
                            color: '#007aff',
                        }}
                        href={url} target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.label}
                    </a> :
                    <span style={{ marginLeft: '10px', color: 'grey' }}>{item.label}</span>
                }
            </div>
        );
    };

    return (
        <div
            style={{
                maxWidth: '600px',
                margin: '0 auto',
                padding: '20px',
                borderRadius: '10px',
                backgroundColor: 'white',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            }}
        >
            <div
                style={{
                    marginBottom: '20px',
                    borderBottom: '1px solid #EEE',
                    paddingBottom: '20px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        marginBottom: '35px',
                    }}
                >
                    <div style={{ fontSize: '18px', fontWeight: 'bold'}}>{`${t('documentList:fileNumber')}: ${vehicle.Project}`}</div>
                    <div style={{ fontSize: '18px', fontWeight: 'bold'}}>{`${t('documentList:registrationNumber')}: ${vehicle.Immatriculation}`}</div>
                    <div style={{ fontSize: '18px', fontWeight: 'bold'}}>{`${t('documentList:VIN')}: ${vehicle.NumeroSerie}`}</div>
                </div>
                {documentItems.map(renderDocumentItem)}
            </div>
            <div
                className="navigation"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                }}
            >
                <button
                    className="document-navigation-button"
                    style={{
                        width: '120px',
                        padding: '10px 15px',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                    }}
                    onClick={() => handleChangeVehicle(-1)}
                    disabled={isFirst}
                >
                    {t('documentList:previous')}
                </button>
                <button
                    className="document-navigation-button"
                    style={{
                        width: '120px',
                        padding: '10px 15px',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                    }}
                    onClick={() => handleChangeVehicle(1)}
                    disabled={isLast}
                >
                    {t('documentList:next')}
                </button>
            </div>
        </div>
    );
};

export default DocumentList;
