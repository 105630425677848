import React, { useState } from 'react';
import { MenuItem, Select, FormControl, InputBase } from '@mui/material';
import LANGUAGES from '../utils/constants/languages';
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('fr');

    const handleChange = (event) => {
        setSelectedLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
        <FormControl style={{ width: '150px' }}>
            <Select
                style={{ height: '65px' }}
                labelId="language-selector-label"
                id="language-selector"
                value={selectedLanguage}
                label="Langue"
                onChange={handleChange}
                input={<InputBase />}
            >
                {LANGUAGES.map((language) => (
                    <MenuItem
                        key={language.id}
                        value={language.id}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <img
                                src={`/images/flags/${language.id}.png`}
                                alt={language.name}
                                style={{ width: '20px' }}
                            />
                            <Typography style={{ fontSize: '14px' }}>
                                {language.name}
                            </Typography>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
