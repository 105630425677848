import React from 'react';

const LoginPage = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                color: '#344049',
                fontFamily: 'Segoe UI',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '30px',
                }}
            >
                <img
                    src={`/images/logo/logo_VPauto.svg`}
                    alt="logo VPauto"
                    style={{height: '105px'}}
                />
                <div
                    style={{
                        fontSize: '50px',
                        fontWeight: 'bold',
                    }}
                >
                    Documents Véhicule VPauto
                </div>
                <div>Vous devez vous connecter afin d'accéder aux fonctionnalités.</div>
                <a
                    className="loginPage-button"
                    style={{
                        width: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px 15px',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        transition: 'background-color 0.3s',
                        textDecoration: 'none',
                    }}
                    href="/.auth/login/b2c"
                >
                    Se connecter
                </a>
                <a
                    className="loginPage-link"
                    style={{
                        color: '#344049',
                        transition: 'text-decoration 0.3s',
                    }}
                    href="/.auth/login/aad"
                >
                    Se connecter en tant qu'administrateur VPauto
                </a>
            </div>
        </div>
    );
};

export default LoginPage;
