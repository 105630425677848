import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import {useTranslation} from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import VehicleService from "../services/Vehicle/VehicleService";
import SearchForm from "../components/SearchForm";
import DocumentList from "../components/DocumentList";
import Header from "../components/Header";

function HomePage() {
    const {t} = useTranslation();
    const timer = useRef(null);
    const cancelToken = useRef(null);
    const [loading, setLoading] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);

    const handleSearch = (searchType, searchTerm) => {
        setLoading(true);
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            if (cancelToken.current) {
                cancelToken.current.cancel();
            }
            cancelToken.current = axios.CancelToken.source();

            VehicleService.getVehicleData(searchType, searchTerm, cancelToken.current)
                .then(response => {
                    setLoading(false);
                    if (response?.data.d && Array.isArray(response?.data.d.results)) {
                        if (response?.data.d.results.length === 0) {
                            toast.warn(t('homePage:noResult'), {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                        setVehicleList((prevVehicleList) => [...prevVehicleList, ...response?.data.d.results]);
                    } else {
                        console.error('Unexpected response format', response?.data);
                        setVehicleList([]);
                    }
                })
                .catch(error => {
                    console.error('There has been a problem with your get operation:', error);
                    throw error;
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 300);
    };

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            if (cancelToken.current) {
                cancelToken.current.cancel();
            }
        };
    }, []);

    return (
        <div>
            <ToastContainer/>
            <Header/>
            <SearchForm onSearch={handleSearch} loading={loading}/>
            <DocumentList vehicleList={vehicleList}/>
        </div>
    );
}

export default HomePage;
