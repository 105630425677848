import React, {createContext, useContext, useEffect, useState} from 'react';
import UserService from "../services/User/UserService";

const AuthContext = createContext();
const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        UserService.getUserData()
            .then(response => {
                if (response.data && response.data.clientPrincipal) {
                    setUser(response.data.clientPrincipal);
                }
            })
            .catch(error => {
                console.error('User verification error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <AuthContext.Provider value={{ user, isLoading }}>
            {!isLoading && children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, useAuth };
