import React from 'react';
import ReactDOM from 'react-dom/client';
import {AuthProvider} from "./contexts/AuthContext";
import {ThemeProvider} from '@mui/material/styles';
import App from './App';
import theme from './assets/styles/theme';
import './i18n/i18n';
import './assets/styles/main.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>
);
